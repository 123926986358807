import React from "react";
import Slider from "../../../components/slider/Slider";
import Button from "../../../components/button/Button";
import { images } from "../../../data/imagesData";
import "./portofoliopage.scss";

const Portofoliopage = () => {
  return (
    <div className="portofolio-wrapper">
      {/* Heading */}
      <div className="title-heading">
        {/* title */}
        <div className="th-text">
          <h1>Portofolio</h1>
        </div>
        {/* button */}
        <div className="th-button">
          <a href="/portofolio">
            {" "}
            <Button name="See More" />
          </a>
        </div>
      </div>

      {/* Image Slider */}
      <div className="pw-slider">
        {/* <Slider /> */}
        <Slider images={images} />
      </div>
    </div>
  );
};

export default Portofoliopage;
