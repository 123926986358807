import React from "react";
import { PiArrowUpRightThin } from "react-icons/pi";
import "./bannerpage.scss";

const Bannerpage = () => {
  //Scroll to footer
  const bottom = () => {
    window.scrollTo({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="banner-wrapper">
      <div className="banner-description">
        {" "}
        <div className="banner-icon">
          {" "}
          <PiArrowUpRightThin className="banner-arrow" />
        </div>
        <div onClick={bottom} className="banner-text">
          <h1>
            Let's <span id="text-mark">design</span> your living
          </h1>
          <h1>space together.</h1>
        </div>
      </div>
    </div>
  );
};

export default Bannerpage;
