import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./app.scss";
import Home from "./pages/home/Home";
import Portofoliomain from "./pages/portofolio/portofolio_main/Portofoliomain";
import Portofolioinfo from "./pages/portofolio/portofolio_info/Portofolioinfo";
import About from "./pages/about/About";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/portofolio" element={<Portofoliomain />}></Route>
        <Route path="/portofolio/:name" element={<Portofolioinfo />}></Route>
        <Route path="/about" element={<About />}></Route>
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
