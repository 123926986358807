import React from "react";
import "./expandednav.scss";
import { motion } from "framer-motion";
import { menuSlide } from "./animation";

const Expandednav = () => {
  const items = ["Home", "Portofolio", "About"];

  return (
    <motion.div
      className="expanded-wrapper"
      variants={menuSlide}
      initial="initial"
      animate="enter"
      exit="exit"
    >
      <div className="expanded-menu">
        <div className="menu-list">
          <a href="/">Home</a>
          <a href="/portofolio">Portofolio</a>
          <a href="/about">About</a>
        </div>
      </div>
    </motion.div>
  );
};

export default Expandednav;
