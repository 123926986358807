import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./portofolioinfo.scss";
import Button from "../../../components/button/Button";
import { gsap, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Portofolioinfo = () => {
  const location = useLocation();
  const itemRef = useRef(Array(location.state.gallery.length).fill(null));
  const title = useRef(null);
  const container = useRef(null);
  const image = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Always on top
  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Top image reveal
  // img ref = mask
  useLayoutEffect(() => {
    const cont = container.current;
    const img = image.current;
    const width = cont.offsetWidth;

    gsap.fromTo(
      img,
      { duration: 1, x: 0 },
      { duration: 1.3, x: width, ease: Power1.easeOut }
    );
  }, []);

  // Title animation
  useLayoutEffect(() => {
    const ti = title.current;

    if (screenWidth >= 1440) {
      gsap.fromTo(
        ti,
        {
          duration: 1,
          x: -50,
          opacity: 0,
        },
        {
          duration: 1,
          x: 0,
          opacity: 1,
          scrollTrigger: {
            start: "5%",
          },
        }
      );
    } else {
      gsap.fromTo(
        ti,
        { x: -50, opacity: 0 },
        { x: 0, opacity: 1, duration: 1 }
      );
    }
  }, [screenWidth]);

  return (
    <div className="portofolio-info-wrapper">
      {/* Banner */}
      <div className="pi-banner">
        {/* Image */}
        <div className="image" ref={container}>
          <img src={`/assets/gallery/${location.state.url}`} alt="banner" />
          <div className="mask" ref={image}></div>
        </div>
        {/* Info */}
        <div className="image-info">
          {/* Description Details*/}
          <div className="image-info-name">
            <h1 ref={title}>{location.state.name}</h1>
            <h3>{location.state.type}</h3>
            <div className="details">
              <div className="area">
                <h4>Area</h4>
                <p> {location.state.area}</p>
              </div>
              <div className="year">
                <h4>Year</h4>
                <p> {location.state.year}</p>
              </div>
              <div className="location">
                <h4>Location</h4>
                <p>{location.state.location}</p>
              </div>
            </div>
          </div>

          {/* Description  */}
          <div className="image-info-description">
            <p>
              This modern sanctuary is a masterpiece of minimalist architecture.
              With its seamless integration of form and function, the house
              exudes a sense of calm and sophistication. The use of natural
              materials and a neutral color palette enhances the tranquil
              atmosphere, making it an ideal space for relaxation and
              contemplation.
            </p>
            <svg width="90%">
              <line x1="0" y1="50%" x2="100%" y2="50%" />
            </svg>
          </div>
        </div>
      </div>

      {/* Gallery */}
      <div className="pi-gallery">
        {/* Image List */}
        <div className="gallery-wrapper-top">
          <div className="grid-list">
            {location.state.gallery.map((image, index) => {
              if (index < 4) {
                return (
                  <img
                    src={`/assets/gallery/${image}`}
                    ref={(el) => (itemRef.current[index] = el)}
                    alt="gallery"
                    key={index}
                  ></img>
                );
              }
            })}
          </div>
        </div>

        {/* Image, Description, Back Button */}
        <div className="gallery-wrapper-bottom">
          <div className="left">
            <p>
              This modern sanctuary is a masterpiece of minimalist architecture.
              With its seamless integration of form and function, the house
              exudes a sense of calm and sophistication. The use of natural
              materials and a neutral color palette enhances the tranquil
              atmosphere, making it an ideal space for relaxation and
              contemplation.
            </p>

            {/* Back Button */}
            <div className="back-button">
              <a href="/portofolio">
                <Button name="Portofolio" />
              </a>
            </div>
          </div>

          <div className="right">
            <img src={`/assets/gallery/${location.state.url}`} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portofolioinfo;
