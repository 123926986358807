import React, { useRef, useLayoutEffect, useState } from "react";
import Accordion from "../../../components/accordion/Accordion";
import { accordion } from "../../../data/serviceData";
import { about_images } from "../../../data/imagesData";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./servicepage.scss";
gsap.registerPlugin(ScrollTrigger);

const Servicepage = () => {
  const [selectedImage, setSelecetedImage] = useState(0);
  const itemRef = useRef(null);
  const itemRef_2 = useRef(null);

  useLayoutEffect(() => {
    const el_2 = itemRef_2.current;

    // Title
    gsap.fromTo(
      el_2,
      { autoAlpha: 0, y: 150 },
      {
        autoAlpha: 1,
        duration: 1,
        y: 0,
        scrollTrigger: {
          start: "38%",
        },
      }
    );
  }, []);

  return (
    <div className="service-wrapper">
      <div className="sw-content">
        {/* Left Content */}
        <div className="sw-left" ref={itemRef}>
          <img
            src={`./assets/gallery/${about_images[selectedImage]}`}
            alt=""
          ></img>
        </div>
        {/* Right Content */}
        <div className="sw-right">
          <div className="right-description" ref={itemRef_2}>
            <h2>
              We offer a full range of architectural services, ensuring each
              project is meticulously planned and executed.
            </h2>
          </div>

          <div className="right-content">
            {accordion.map((data, index) => (
              <div
                onMouseOver={() => {
                  setSelecetedImage(index);
                }}
                key={index}
              >
                {" "}
                <Accordion data={data} i={index} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicepage;
