import React, { useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./accordion.scss";
gsap.registerPlugin(ScrollTrigger);

const Accordion = ({ data, i }) => {
  const itemRef = useRef(Array(data.length).fill(null));

  useLayoutEffect(() => {
    const el_3 = itemRef.current;

    el_3.forEach((cardRef, index) => {
      gsap.set(cardRef, { autoAlpha: 0, y: 50 });
      ScrollTrigger.create({
        trigger: cardRef,
        start: "20px bottom",
        onEnter: () =>
          gsap.to(cardRef, {
            autoAlpha: 1,
            ease: "power1",
            duration: 1,
            stagger: 0.5,
            delay: 0.3,
            y: 0,
          }),
      });
    });
  }, []);

  return (
    <div className="accordion-wrapper" ref={(el) => (itemRef.current[i] = el)}>
      {/* Title & icon */}
      <div className="aw-title">
        <div className="aw-t-title">
          <h1>{data.title}</h1>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
