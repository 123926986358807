import React, { useLayoutEffect, useRef } from "react";
import "./about.scss";
import { about_images } from "../../data/imagesData";
import { gsap, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  // top
  const images = about_images;
  const title = useRef(null);
  // middle banner
  const mid = useRef(null);
  const container2 = useRef(null);

  // Title top
  useLayoutEffect(() => {
    const ti = title.current;
    gsap.set(ti, { autoAlpha: 0, x: -20 });
    gsap.to(ti, { autoAlpha: 1, x: 0, duration: 1, stagger: 0.1 });
  }, []);

  // Banner middle
  // img ref = mask
  useLayoutEffect(() => {
    const con2 = container2.current;
    const img = mid.current;
    const height = con2.offsetHeight;

    gsap.fromTo(
      img,
      { duration: 1, y: 0 },
      {
        duration: 1.5,
        y: height,
        ease: Power1.easeOut,
        delay: 0.3,
        scrollTrigger: {
          start: "20%",
        },
      }
    );
  }, []);

  return (
    <div className="about-wrapper">
      {/* Introduction Section*/}
      <div className="aw-introduction">
        <div className="i-description">
          <div className="title" ref={title}>
            {" "}
            <h1>Bluelake</h1>
            <h2 id="italic">Studio</h2>
          </div>
          <p>
            BlueLake Studio embodies the quintessence of architectural
            ingenuity, where each creation is a symphony of form, function, and
            finesse. Our practice is predicated on a holistic approach that
            meticulously considers every element
          </p>
        </div>
        <div className="i-banner">
          <div className="i-banner-container">
            <img src="assets/banner_1.jpg" alt="" />
          </div>
        </div>
      </div>

      {/* Values Section*/}
      <div className="aw-values">
        <div className="v-title">
          <h3>Our Mission</h3>
        </div>
        <div className="line">
          <svg>
            <line x1={0} y1={1} x2={`100%`} y2={1}></line>
          </svg>
        </div>
        <div className="v-list">
          <div className="list-content">
            <h2>01.</h2>
            <p>
              At our interior design firm, we're not just in the business of
              creating beautiful spaces; we're in the business of transforming
              lives.
            </p>
          </div>
          <div className="list-content">
            <h2>02.</h2>
            <p>
              At our interior design firm, we're not just in the business of
              creating beautiful spaces; we're in the business of transforming
              lives.
            </p>
          </div>
          <div className="list-content">
            <h2>03.</h2>
            <p>
              At our interior design firm, we're not just in the business of
              creating beautiful spaces; we're in the business of transforming
              lives.
            </p>
          </div>
        </div>
      </div>

      {/* Banner Section*/}
      <div className="aw-banner" ref={container2}>
        <img src="assets/gallery/img4.jpg" alt="" />
        <div className="mask" ref={mid}></div>
      </div>
      {/* Team Section*/}
      <div className="aw-team">
        <div className="t-title">
          <h1>Meet Our Team</h1>
        </div>
        <div className="t-team">
          <div className="team-grid">
            <div className="team-image">
              <img src="assets/about/men1.jpg" alt="" />
              <h3>John Doe</h3>
              <p>Owner</p>
            </div>
            <div className="team-image">
              <img src="assets/about/men2.jpg" alt="" />
              <h3>John Doe</h3>
              <p>Owner</p>
            </div>
            <div className="team-image">
              <img src="assets/about/women1.jpg" alt="" />
              <h3>John Doe</h3>
              <p>Owner</p>
            </div>
          </div>
        </div>
      </div>

      {/* Portofolio Section */}
      <div className="aw-portofolio">
        <div className="p-top">
          <div className="top-number">
            <div className="number-list">
              <h2>25+</h2>
              <p>Completed Project</p>
            </div>
            <div className="number-list">
              <h2>15+</h2>
              <p>Ongoing Project</p>
            </div>
            <div className="number-list">
              <h2>20+</h2>
              <p>Clients</p>
            </div>
          </div>
          <div className="top-description">
            <p>
              Our company has worked with many clients, creating beautiful and
              functional buildings that meet their needs. We take pride in
              turning ideas into reality, ensuring each project is unique and
              well-crafted.
            </p>
          </div>
        </div>

        <div className="p-bottom">
          <div className="bottom-container">
            {images.map((image, index) => (
              <div className="bottom-image" key={index}>
                <img src={`/assets/gallery/${image}`} alt="" />
              </div>
            ))}
          </div>
          <div className="bottom-container">
            {images.map((image, index) => (
              <div className="bottom-image" key={index}>
                <img src={`/assets/gallery/${image}`} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
