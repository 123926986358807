import React from "react";
import { FaLinkedinIn, FaPinterestP } from "react-icons/fa6";
import { PiInstagramLogoLight, PiCopyrightLight } from "react-icons/pi";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="foo-wrapper">
        <div className="contact">
          {/* Left Content */}
          <div className="contact-desc">
            {/* Short text */}
            <div className="cd-text">
              <p>
                Our team is always ready for your
                <span className="mark"> inquiries.</span> We are excited to
                receive your ideas and work on it.
              </p>
            </div>
            {/* Address and contact*/}
            <div className="cd-address">
              <div className="cd-a-contact">
                <h2>Contact</h2>
                <p>bluelake.id@gmail.com</p>
                <a href="https://wa.me/6289608558585" target="_blank">
                  <p>+62 896-0855-8585</p>
                </a>
              </div>
              <div className="cd-a-address">
                <h2>Address</h2>
                <a
                  href="https://maps.app.goo.gl/9wtS5JgTibc8pjhe8"
                  target="_blank"
                >
                  <p>Bandung, Indonesia</p>
                </a>
              </div>
            </div>
          </div>

          {/* Right Content */}
          <div className="contact-right">
            <div className="cl-content">
              {/* Menu */}
              <div className="content-menu">
                {" "}
                <a href="/">Home</a>
                <a href="/portofolio">Portofolio</a>
                <a href="/about">About</a>
              </div>

              {/* Socials */}
              <div className="cd-social">
                <a
                  href="https://www.instagram.com/bluelake.id/?hl=id"
                  target="_blank"
                >
                  <PiInstagramLogoLight />
                </a>
                <a href="#">
                  <FaLinkedinIn />
                </a>
                <a href="#">
                  <FaPinterestP />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Company Copyright */}
        <div className="copyright">
          <div className="line" />
          <div className="copy-text">
            {" "}
            <span>
              {" "}
              <PiCopyrightLight />
            </span>
            <p>2024 Blue Lake Studio.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
