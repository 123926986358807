import React, { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { PiArrowUpRightThin } from "react-icons/pi";
import { SlArrowUp } from "react-icons/sl";
import { images } from "../../../data/imagesData";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./portofoliomain.scss";
gsap.registerPlugin(ScrollTrigger);

const Portofoliomain = () => {
  const itemRef = useRef(Array(images.length).fill(null));

  // Image Reveal Gallery
  useLayoutEffect(() => {
    itemRef.current.forEach((cardRef, index) => {
      if (index < 3) {
        gsap.set(cardRef, { autoAlpha: 0, y: 150 });
        gsap.to(cardRef, {
          autoAlpha: 1,
          duration: 1.2,
          y: 0,
        });
      } else {
        gsap.set(cardRef, { autoAlpha: 0, y: 50 });
        ScrollTrigger.create({
          trigger: cardRef,
          onEnter: () =>
            gsap.to(cardRef, {
              autoAlpha: 1,
              // ease: "power1",
              duration: 1.2,
              delay: 0.2,
              y: 0,
            }),
        });
      }
    });
  }, []);

  //Back to top button
  const top = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="portofolio-main-wrapper">
      {/* Heading */}

      <div className="pmw-heading">
        <h1>Portofolio</h1>
        <h4>
          "Take a stroll through our collection of incredible buildings, where
          you'll find dozens of projects made with lots of care and clever
          thinking. From big city landmarks to peaceful spots in the
          countryside, each one is a great example of how we love to come up
          with new ideas."
        </h4>
        <svg height="30px" width="40%">
          <line x1="0" y1="50%" x2="100%" y2="50%" />
        </svg>
      </div>

      {/* Gallery */}
      <div className="pmw-content">
        <div className="content-grid">
          <div className="grid-list">
            {images.map((image, index) => (
              <Link
                to={`/Portofolio/${image.url_name}`}
                key={index}
                state={image}
              >
                <div className="image-container">
                  <div
                    className="image"
                    ref={(el) => (itemRef.current[index] = el)}
                  >
                    <img src={`assets/gallery/${image.url}`} alt="" />
                    <div className="image-info">
                      <div className="info-left">
                        <h3>{image.name}</h3>
                      </div>
                      <div className="info-right">
                        <PiArrowUpRightThin
                          className="info-arrow"
                          size="40px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="back-top-button" onClick={top}>
        <SlArrowUp className="top" size="13px" />
        <SlArrowUp className="bottom" size="13px" />
        {/* <p>back to top</p> */}
      </div>
    </div>
  );
};

export default Portofoliomain;
