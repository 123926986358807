import React from "react";
import { PiArrowUpRightThin } from "react-icons/pi";
import "./button.scss";

const Button = (props) => {
  return (
    <div className="button-wrapper">
      <div className="b-icon">
        <PiArrowUpRightThin />
      </div>
      <div className="b-button">
        <button>
          <h4>{props.name}</h4>
        </button>
      </div>
    </div>
  );
};

export default Button;
