import React from "react";
import Homepage from "./home_page/Homepage";
import Aboutpage from "./about_page/Aboutpage";
import Portofoliopage from "./portofolio_page/Portofoliopage";
import Servicepage from "./service_page/Servicepage";
import Bannerpage from "./banner_page/Bannerpage";
import "./home.scss";

const Home = () => {
  return (
    <div className="container">
      <section className="home">
        <Homepage />
      </section>
      <section className="about">
        <Aboutpage />
      </section>
      <section className="portofolio">
        <Portofoliopage />
      </section>
      <section className="service">
        <Servicepage />
      </section>
      <section className="banner">
        <Bannerpage />
      </section>
    </div>
  );
};

export default Home;
