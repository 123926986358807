import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import "./navbar.scss";
import Expandednav from "./Expandednav";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="navbar">
      <div className="nav-wrapper">
        <span>
          <img src="/assets/logo.png" alt="" />
        </span>
        <div className="menu">
          <a href="/">Home</a>
          <a href="/portofolio">Portofolio</a>
          <a href="/about">About</a>
        </div>
      </div>

      {/* Hamburger Menu */}
      <div onClick={() => setIsActive(!isActive)} className="button">
        <div className={`burger ${isActive ? "burgerActive" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>

      {/* Expanded Nav */}
      <AnimatePresence>{isActive && <Expandednav />}</AnimatePresence>
    </div>
  );
};

export default Navbar;
