export const images = [
  {
    id: 1,
    type: "Commercial",
    url: "other_1.jpeg",
    url_name: "Food-Stand-A",
    name: "Food Stand A",
    area: "193 m²",
    location: "Bandung",
    year: "2024",
    gallery: [
      "other_1.jpeg",
      "other_1.jpeg",
      "other_1.jpeg",
      "other_1.jpeg",
      "other_1.jpeg",
    ],
  },
  {
    id: 2,
    type: "Commercial",
    url: "other_2.jpeg",
    url_name: "Food-Stand-B",
    name: "Food Stand B",
    area: "90 m²",
    location: "Jakarta",
    year: "2024",
    gallery: [
      "other_2.jpeg",
      "other_2.jpeg",
      "other_2.jpeg",
      "other_2.jpeg",
      "other_2.jpeg",
    ],
  },
  {
    id: 3,
    type: "Interior Design",
    url: "interior_1.jpg",
    url_name: "Bedroom-X-House",
    name: "Bedroom X House",
    area: "120 m²",
    location: "Bandung",
    year: "2023",
    gallery: [
      "interior_1.jpg",
      "interior_1.jpg",
      "interior_1.jpg",
      "interior_1.jpg",
      "interior_1.jpg",
    ],
  },
  {
    id: 4,
    type: "Interior Design",
    url: "interior_2.jpg",
    url_name: "Dining-Room-X-House",
    name: "Dining Room X House",
    area: "200 m²",
    location: "Bandung",
    year: "2023",
    gallery: [
      "interior_2.jpg",
      "interior_2.jpg",
      "interior_2.jpg",
      "interior_2.jpg",
      "interior_2.jpg",
    ],
  },
  {
    id: 5,
    type: "Commercial",
    url: "other_3.jpg",
    url_name: "Food-Stand-C",
    name: "Food Stand C",
    area: "50 m²",
    location: "Bandung",
    year: "2023",
    gallery: [
      "other_3.jpg",
      "other_3.jpg",
      "other_3.jpg",
      "other_3.jpg",
      "other_3.jpg",
    ],
  },
  {
    id: 6,
    type: "Commercial",
    url: "img2.jpg",
    url_name: "Food-Stand-D",
    name: "Food Stand D",
    area: "193 m²",
    location: "Bandung",
    year: "2024",
    gallery: ["img2.jpg", "img2.jpg", "img2.jpg", "img2.jpg", "img2.jpg"],
  },
  {
    id: 7,
    type: "Interior Design",
    url: "img4.jpg",
    url_name: "Living-Room-Y-House",
    name: "Living Room Y House",
    area: "90 m²",
    location: "Jakarta",
    year: "2024",
    gallery: ["img4.jpg", "img4.jpg", "img4.jpg", "img4.jpg", "img4.jpg"],
  },
  {
    id: 8,
    type: "Commercial",
    url: "img8.jpg",
    url_name: "Food-Stall-E",
    name: "Food Stall E",
    area: "120 m²",
    location: "Bandung",
    year: "2023",
    gallery: ["img8.jpg", "img8.jpg", "img8.jpg", "img8.jpg", "img8.jpg"],
  },
  {
    id: 9,
    type: "Residential",
    url: "img10.jpg",
    url_name: "Y-House-Mekarwangi",
    name: "Y House Mekarwangi",
    area: "200 m²",
    location: "Bandung",
    year: "2023",
    gallery: ["img10.jpg", "img10.jpg", "img10.jpg", "img10.jpg"],
  },
  {
    id: 10,
    type: "Interior Design",
    url: "img11.jpg",
    url_name: "Living-Room-Y-House",
    name: "Living Room Y House",
    area: "50 m²",
    location: "Bandung",
    year: "2023",
    gallery: ["img11.jpg", "img11.jpg", "img11.jpg", "img11.jpg", "img11.jpg"],
  },
];

export const about_images = [
  "img1.jpg",
  "img2.jpg",
  "img4.jpeg",
  "img9.jpg",
  "img12.jpg",
  "img4.jpg",
  "other_3.jpg",
];
